
// vue
import { defineComponent, ref, computed } from 'vue';

// icons
import { add, close } from 'ionicons/icons';

// components
import {
  IonPage,
  IonGrid,
  IonCard,
  IonInput,
  IonIcon,
  IonFooter,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonTitle,
  IonSearchbar,
  IonToolbar,
  modalController,
  loadingController
} from '@ionic/vue';

import config from '@/config';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

import TeacherService from '@/services/TeacherService';

export default defineComponent({
  name: 'RoleInSchoolSelectModal',
  props: ["hideHeader", "selectedRolesInSchool"],
  components: {
    IonPage,
    IonGrid,
    IonCard,
    IonInput,
    IonIcon,
    IonFooter,
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonTitle,
    IonSearchbar,
    IonToolbar,
  },
  setup(props) {
    const { closeModal, presentToast } = utils();
    const store = useStore();
    const user = computed(() => store.state.user);

    const searchKeyword = ref("");

    const workingSelectedValues = ref([...(props.selectedRolesInSchool || [])]);
    const rolesInSchool = ref([...new Set(workingSelectedValues.value.concat(config.formOptions.rolesInSchool))]);

    const isChecked = (value: string) => {
      return workingSelectedValues.value.find((item) => item === value) !== undefined;
    };

    const confirmChanges = async () => {
      if (props.hideHeader) { // from TeacherRolesModal
        const updateSchoolRoles = workingSelectedValues.value.join(" , ");

        const loading = await loadingController.create({});
        await loading.present();

        // Check remove class roles (set status to removed)
        TeacherService.updateTeacher(user.value, { schoolRoles: updateSchoolRoles });
        store.commit('updateTeacher', { schoolRoles: updateSchoolRoles });
        presentToast("Saved successfully!");

        loading.dismiss();
      }
      else {
        await modalController.dismiss({ workingSelectedValues })
      }
    };

    const checkboxChange = (ev) => {
      const { checked, value } = ev.detail;
      if (checked){
        workingSelectedValues.value.push(value)
      } else {
        workingSelectedValues.value = workingSelectedValues.value.filter(role => role!= value)
      }
    };

    return {
      add, close,

      searchKeyword,
      rolesInSchool,
      workingSelectedValues,

      isChecked,
      closeModal, confirmChanges,
      checkboxChange,

      onAddNewRole: () => {
        if (searchKeyword.value) {
          workingSelectedValues.value.unshift(searchKeyword.value.trim());
          searchKeyword.value = '';
          rolesInSchool.value = [...new Set(workingSelectedValues.value.concat(config.formOptions.rolesInSchool))];
        }
      }
    };
  },
});
