
// vue
import { computed } from '@vue/reactivity';
import { defineComponent, ref,  } from 'vue';

// icons
import { add, close, arrowBack, arrowForward, } from 'ionicons/icons';

// components
import {
  IonHeader, IonToolbar, IonTitle, IonContent, IonButton, IonButtons,
  IonList, IonItem, IonLabel, IonInput, IonTextarea, IonIcon, IonCheckbox, IonFooter,
  modalController
} from '@ionic/vue';

import config from '@/config';
import { useStore } from '@/store';

export default defineComponent({
  name: 'RoleInClassSelectModal',
  props: ["initialSection", "prefilledRole", "prefilledClasses"],
  components: {
    IonHeader, IonToolbar, IonTitle, IonContent, IonButton, IonButtons,
    IonList, IonItem, IonLabel, IonInput, IonTextarea, IonIcon, IonCheckbox, IonFooter,
  },
  setup(props) {
    const section = ref(props.initialSection || 1); // role -> classes

    const customRole = ref("");
    const selectedRole = ref(props.prefilledRole || "");

    const customClass = ref("");
    const selectedClasses = ref<any>(props.prefilledClasses?.slice() || []);
    const extraClasses = ref<any>(props.prefilledClasses?.slice() || []);

    // store variables
    const store = useStore();
    const allElectives = computed(() => store.state.allElectives);

    return {
      // icons
      add, close, arrowBack, arrowForward,

      // variables
      section,
      selectedRole, customRole,
      selectedClasses, customClass,

      // methods
      getTitle: () => {
        if (section.value == 1) return `Select your role`;
        return `You are a ${selectedRole.value} in class(es):`;
      },
      cancelChanges: async () => (await modalController.dismiss({})),
      confirmChanges: async (selectedRole, selectedClasses) => (await modalController.dismiss({ selectedRole, selectedClasses, })),

      // Role
      rolesInClass: () => {
        const baseRoles = [props.prefilledRole, 'Class teacher', ...(config.coreSubjects.map(s => `${s} teacher`))];
        const roles = baseRoles.concat(allElectives.value.map(e => (`${e.shortName || e.name} teacher`)));
        return [...new Set(roles.filter(r => r))];
      },
      onSelectRole: (role) => {
        if (role) {
          selectedRole.value = role.trim();
          section.value = 2; // select class(es)
        }
      },

      // Classes
      allClasses: () => {
        const basicClasses: any = [];
        for (let form = 1; form <= 6; form++) {
          for (const label of ['A', 'B', 'C', 'D', 'E', 'F']) {
            basicClasses.push(`${form}${label}`);
          }
        }
        return [...new Set(extraClasses.value.concat(basicClasses))];
      },

      onAddNewClass: () => {
        if (customClass.value) {
          const newClass = customClass.value.trim().toUpperCase();
          selectedClasses.value.unshift(newClass);
          customClass.value = '';
          extraClasses.value.unshift(newClass); // put to front
        }
      },
      isChecked: (value) => (selectedClasses.value.find((item) => item === value) !== undefined),
      checkboxChange: (ev) => {
        const { checked, value } = ev.detail;
        if (checked){
          selectedClasses.value.push(value);
        } else {
          selectedClasses.value = selectedClasses.value.filter(cl => cl != value);
        }
      },
    };
  },
});
