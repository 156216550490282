import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              (!_ctx.hideHeader)
                ? (_openBlock(), _createBlock(_component_ion_toolbar, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Select your position(s) in the school")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_buttons, { slot: "end" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            slot: "icon-only",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, {
                    lines: "none",
                    fill: "outline"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createVNode(_component_ion_button, {
                        slot: "start",
                        size: "normal",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onAddNewRole()))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "start",
                            icon: _ctx.add
                          }, null, 8, ["icon"]),
                          _createTextVNode(" Add ")
                        ]),
                        _: 1
                      }, 512), [
                        [_vShow, _ctx.searchKeyword]
                      ]),
                      _createVNode(_component_ion_input, {
                        type: "text",
                        modelValue: _ctx.searchKeyword,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchKeyword) = $event)),
                        placeholder: "Type here if your position is not listed"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list, { style: {"max-height":"65vh","overflow-y":"scroll"} }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rolesInSchool, (role) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: role,
                          button: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_checkbox, {
                              "label-placement": "end",
                              justify: "start",
                              value: role,
                              checked: _ctx.isChecked(role),
                              onIonChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.checkboxChange($event)))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(role), 1)
                              ]),
                              _: 2
                            }, 1032, ["value", "checked"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                color: "success",
                expand: "block",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.confirmChanges()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Confirm ")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}