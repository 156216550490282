import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6594bc68"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-margin-vertical ion-text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              (!_ctx.hideHeader)
                ? (_openBlock(), _createBlock(_component_ion_toolbar, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Your role(s) in class(es), if applicable.")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_buttons, { slot: "end" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            slot: "icon-only",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancelChanges()))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              _createElementVNode("form", {
                onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.confirmChanges()), ["prevent"]))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rolesInClasses, (rc, i) => {
                  return (_openBlock(), _createBlock(_component_ion_card, {
                    style: {"border-left":"8px solid #EF6C00"},
                    key: i
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, { lines: "none" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createElementVNode("h2", null, "Role " + _toDisplayString(i+1), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_ion_buttons, { slot: "end" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_button, {
                                onClick: ($event: any) => (_ctx.removeRoleInClass(i))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    slot: "icon-only",
                                    icon: _ctx.close
                                  }, null, 8, ["icon"])
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, { size: "6" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_textarea, {
                                fill: "outline",
                                label: "You are a",
                                labelPlacement: "floating",
                                type: "text",
                                modelValue: rc.role,
                                "onUpdate:modelValue": ($event: any) => ((rc.role) = $event),
                                onClick: ($event: any) => (_ctx.openRoleInClassSelectModal(i, rc.role, rc.classes)),
                                required: ""
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "onClick"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_ion_col, { size: "6" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_textarea, {
                                fill: "outline",
                                label: "in class(es)",
                                labelPlacement: "floating",
                                type: "text",
                                modelValue: rc.classes,
                                "onUpdate:modelValue": ($event: any) => ((rc.classes) = $event),
                                onClick: ($event: any) => (_ctx.openRoleInClassSelectModal(i, rc.role, rc.classes, rc.role ? 2 : 1)),
                                required: ""
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128)),
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_ion_button, {
                    color: "secondary",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.incrementRoleInClass())),
                    shape: "round"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "start",
                        icon: _ctx.add
                      }, null, 8, ["icon"]),
                      _createTextVNode(" Add More ")
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(_component_ion_button, {
                  class: "ion-margin-vertical",
                  type: "submit",
                  expand: "block"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('UserProfilePage.save')), 1)
                  ]),
                  _: 1
                })
              ], 32)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}